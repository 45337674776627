import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintWarg,
  mintPixaNFT,
  tokenURIfinder,
  checkPixaBalance,
  mintThreeWarg,
  checkWargsMinted,
  claimVortical,
  claimVorticalLoop,
  upgradeGem,
  checkDopplers
} from "./utils/interact.js";
import pixagif from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo.gif'
import pixapng from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo-2.png'
import wargsgif from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/PixaWargs.gif'
import wyvernsgif from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/wyverns.gif'
import wizardsgif from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/wizards.gif'
import witchesgif from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/witches.gif'
import charmgif from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/brews.gif'
import bitbrewsgif from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/bitbrews.gif'
import pleasewait from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/pleasewait1.PNG'
import brew0 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_00.png'
import brew1 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_01.png'
import brew2 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_02.png'
import brew3 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_03.png'
import brew4 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_04.png'
import brew5 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_05.png'
import brew6 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_06.png'
import brew7 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_07.png'
import brew8 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_09.png'
import brew9 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_10.png'
import brew10 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_08.png'
import brew11 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_11.png'
import brew12 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_12.png'
import brew13 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_13.png'
import brew14 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_14.png'
import brew15 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_15.png'
import brew16 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_16.png'
import brew17 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_17.png'
import brew18 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_18.png'
import brew19 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_19.png'
import brew20 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_20.png'
import brew21 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_21.png'
import brew22 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_22.png'
import brew23 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_23.png'
import brew24 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_24.png'
import brew25 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_25.png'
import brew26 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_26.png'
import brew27 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_27.png'
import brew28 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_28.png'
import brew29 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_29.png'
import brew30 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_30.png'
import brew31 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_31.png'
import brew32 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_32.png'
import brew33 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_33.png'
import brew34 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_34.png'
import brew35 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_35.png'
import brew36 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_36.png'
import brew37 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_37.png'
import brew38 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_38.png'
import brew39 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_39.png'
import brew40 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_40.png'
import brew41 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_41.png'
import brew42 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_42.png'
import brew43 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_43.png'
import brew44 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_44.png'
import brew45 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_45.png'
import brew46 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_46.png'
import brew47 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_47.png'
import brew48 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_48.png'
import brew49 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_49.png'
import brew50 from 'D:/NFT_development/front_end/new-app-pages/brews/nft-minter-tutorial-main/minter-starter-files/src/sprite_50.png'


const Minter = (props) => {

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [ownership, setAreYouOwner] = useState("");
  const [upgradestatus, setupgradeStatus] = useState("");
  const [pixaBalance, setPixaBalance] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [wargs, setWargsMinted] = useState("");
  const [displayRank, setRank] = useState("");
  const [tokenID, setToken] = useState("");
  const [tokenURI, setTokenURI] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [wizstatus, setWizStatus] = useState(""); 
  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    const {currentwargsminted} = await checkWargsMinted();
    setWallet(address);
    setStatus(status);
    setWargsMinted(currentwargsminted)
    setTokenURI(wargsgif);
    setupgradeStatus("currently selected.")    
    const {walletpixabalance} = await checkPixaBalance(address)
    setPixaBalance(walletpixabalance)
    addWalletListener(); 
  }, []);


  const onTokenChange = async (num) => {
    setToken(num)
    setAreYouOwner("")
    setTokenURI(pleasewait)
    setRank("") 
    setAreYouOwner("please wait... ⏱️") 
    const { tokenwiz} = await tokenURIfinder(num, walletAddress);   
    setTokenURI("https://storage.googleapis.com/pixawyverns/" + tokenwiz + "PWYV.gif")
    setRank(displayRank)
    setAreYouOwner(ownership)
  };


  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintWargPressed = async () => { //TODO: implement
      const { status } = await mintWarg(); 
  };

  const onMintThreeWargPressed = async () => { //TODO: implement
      const { status } = await mintThreeWarg();
  };

  const onMintWargPixaPressed = async () => { //TODO: implement
      const { status } = await mintPixaNFT(walletAddress); 
  };

  const onClaimPressed = async () => { //TODO: implement
      const { status } = await claimVortical();
  };

  const onClaimLoopPressed = async () => { //TODO: implement
      const { status } = await claimVorticalLoop();
  };

  const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkDopplers(name);
    setWizStatus(wizstatus);  
  };

  const onUpgrade1Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 1);
  };

  const onUpgrade2Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 2);
  };

  const onUpgrade3Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 3);
  };

  const onUpgrade4Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 4);
  };

  const onUpgrade5Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 5);
  };


  
  return (
    <div className="Minter">
<div id="stars"></div>
<div id="stars2"></div>
<div id="stars3"></div>


<h5>By using this webpage you agree to the terms & conditions outlined at the bottom of this page. Disclaimer: Use this site at your own risk.</h5>       

<h1>
          <div>
      <img src={pixapng} alt="Image" height={45} width={100} border={0}/>
      </div>
</h1>
      <h2> <a href="https://wizards.pixanft.com" rel="noreferrer">  🧙‍♂️</a>  <a href="https://witches.pixanft.com" rel="noreferrer">  🧙‍♀️</a> <a href="https://wyverns.pixanft.com" rel="noreferrer">  🐲</a> <a href="https://wargs.pixanft.com" rel="noreferrer">  🐺</a>  <a href="https://brews.pixanft.com" rel="noreferrer">  🍺</a> <a href="https://limitededitions.pixanft.com" rel="noreferrer">  🖼️</a> </h2>
      <h5>Click an emoji to navigate to other pages!</h5>         
      <h2> <a href="https://tokens.pixanft.com" rel="noreferrer">  💰</a>  <a href="https://relics.pixanft.com" rel="noreferrer">  🗡️</a> <a href="https://wizardao.pixanft.com" rel="noreferrer">  🎩</a> <a href="https://tomes.pixanft.com" rel="noreferrer">  📖</a> <a href="https://land.pixanft.com" rel="noreferrer">  🏡</a> <a href="https://badges.pixanft.com" rel="noreferrer">  📛</a> </h2>
           
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
  

  
<h1 id="hhh1">📒  The  📒</h1>
      <h1 id="hhh1">BitBrews</h1>
      <h1 id="hhh1">_________</h1>
  
  <h4>The BitBrews are mysterious brews that found their way onto the Bitcoin blockchain... these brews are shrouded in mystery and should be handled carefully.</h4>

<h4>The BitBrews are a collection of 51 ordinal inscriptions on the Bitcoin blockchain. They are the first inscriptions collection created by Pixa and were released in February 2023. For more information about the other collections that exist in the PixaRealm, visit our <a href="https://pixanft.com" rel="noreferrer">  website</a></h4>

<h1 id="hhh1">_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>



<h1 id="hhh1">Collection</h1>
<h1 id="hhh1">_________</h1>
<h4>Total supply: 51</h4>
<h4>Provenance - Inscription #12127 (BitBrew0) - All BitBrews are inscribed under #100k.</h4>
<h4>Click the BitBrew to see more details about their current status. BitBrews are currently being distributed through giveaways. Follow us on <a href="https://twitter.com/pixa_nft" rel="noreferrer">twitter</a> and join the <a href="https://discord.gg/pixa" rel="noreferrer">discord</a> to win a BitBrew!</h4>

<p></p>
<div class="myGallery">
  <div class="item">
    <img id="image" src={brew0} border={2} />
    <span class="caption"> <a href="https://ordinals.com/inscription/94cf4048f88e0916cce7e127edce54023521b62dc217b88555643c6bc905f354i0" rel="noreferrer"> BitBrew0 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
  </div>
  <div class="item">
    <img id="image" src={brew1} border={2}/>
    <span class="caption"><a href="https://ordinals.com/inscription/94d0df998c062309010d6edb6eed09c8bf37428632c976a672264ec952b21607i0" rel="noreferrer"> BitBrew1 </a> <br></br> <button id="mintButton" >
        Claimed
</button></span>
  </div>
  <div class="item">
    <img id="image" src={brew2} border={2}/>
    <span class="caption"><a href="https://ordinals.com/inscription/e881503c1d40133ee7dbef047ffa878f34521412462c99bbb3f428822b8ff175i0" rel="noreferrer"> BitBrew2 </a> <br></br> <button id="mintButton" >
        Available
</button></span>
  </div>
  <div class="item">
    <img id="image" src={brew3} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/2080cbd7c5df2baee0228a68d989a8c86248c70e3cca41dd8acaf8b01037f0fei0" rel="noreferrer"> BitBrew3 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
  </div>
  <div class="item">
    <img id="image" src={brew4} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/ed254491d9ad1941049e1d2584313edb575346d9eff75ec38127ad036f0ebc44i0" rel="noreferrer"> BitBrew4 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
  </div>
  <div class="item">
    <img id="image" src={brew5} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/63f36b0291f18ba6d448882bf198a5b65dd777ca026b4b3f719b5d6187440a91i0" rel="noreferrer"> BitBrew5 </a> <br></br> <button id="mintButton" >
        Claimed 
</button></span>
  </div>
  <div class="item">
    <img id="image" src={brew6} border={2}/>
    <span class="caption"><a href="https://ordinals.com/inscription/6d008dad0b73b6b2822b5a57d896d10a33add07a65a3d4371c9ccb9b5157c338i0" rel="noreferrer"> BitBrew6 </a> <br></br> <button id="mintButton" >
        Claimed
</button> </span>
  </div>
  <div class="item">
    <img id="image" src={brew7} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/d5b104c2a7acccd6c71af5a002943f5b91f7ca0dbe1cd50b06d2e264aa6c2cc4i0" rel="noreferrer"> BitBrew7 </a> <br></br> <button id="mintButton" >
        Available
</button></span>
  </div>
  <div class="item">
    <img id="image" src={brew8} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/4ae5f4e36c870813d4b378eca0fdc2e316262426d201a6301341d837c807e98bi0" rel="noreferrer"> BitBrew8 </a> <br></br> <button id="mintButton" >
        Available
</button></span>
  </div>
  <div class="item">
    <img id="image" src={brew9} border={2}/>
    <span class="caption"><a href="https://ordinals.com/inscription/4662443939615e5da06fd9c8db13628248ce47ad193bdd2b9ed3bfa9ec21baaci0" rel="noreferrer"> BitBrew9 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
  </div>
  <div class="item">
    <img id="image" src={brew10} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/118c25d03b11be0cb2fea5c891bfd412c53a5b8d2901ef758a55a1b98e2d06b4i0" rel="noreferrer"> BitBrew10 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew11} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/a757583e340e1511b7a72a24ac0390eeecfa60ed451e5fc6c8423b4b38ee464ei0" rel="noreferrer"> BitBrew11 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew12} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/2fbbaf304d4d44a5e41d4c2df6341616b692e961cae5bf2d974d3d6fde669d03i0" rel="noreferrer"> BitBrew12 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew13} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/ee3195f882bc155865255d3693065599fa5e90d483ea57a933810ab2d066083bi0" rel="noreferrer"> BitBrew13 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew14} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/62645f0fb6fd85e35fd11c286e739494b3c539bfed15e9a355469072338d6cefi0" rel="noreferrer"> BitBrew14 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew15} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/2f6e111045f90444c2e928a886fad190542f15ab3e53f12e7fdab567b33ad50fi0" rel="noreferrer"> BitBrew15 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew16} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/ee413321ad82f85d7b8ea8f114c810572ca1c283e87cce660adbeca1a9389c66i0" rel="noreferrer"> BitBrew16 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew17} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/4435412aae223b7a0ab74d31ea053aae22f0335a90e138d9f2387193585b46d7i0" rel="noreferrer"> BitBrew17 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew18} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/30ed00c4623593fcdc28041a45d2500e4ee6fd85fc91e1c30020caddbbde2742i0" rel="noreferrer"> BitBrew18 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew19} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/f5341d708900a178c557352192411b0c1777956f9f2dfa8423c6e5f9c96382c4i0" rel="noreferrer"> BitBrew19 </a> <br></br> <button id="mintButton" >
        Available
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew20} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/ad6dcd6d9dd804d8bd191248cfbe7aab558bbfbed25c02d16dd6bf4d58c23f5fi0" rel="noreferrer"> BitBrew20 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew21} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/4812d0d396285ec6cc76a7f9b3b5ee46df69ec3f14d0ca229dde86db07010065i0" rel="noreferrer"> BitBrew21 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew22} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/bb5d49d877cbb405260763ab5bf8a1ffb2e70e57b05e9c368f87d7b74e23a451i0" rel="noreferrer"> BitBrew22 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew23} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/5e23b5909d29739f0f0e1bb559638090f5c4300457f77848782d56d5673d40f3i0" rel="noreferrer"> BitBrew23 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew24} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/c03a43ef7f584598e91066d95d88bb4cd4187b229abfe62afa14bf7c2c0d5552i0" rel="noreferrer"> BitBrew24 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew25} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/fda0c0087ebcd7acca937175133373f4be9e90574f5f5e19d2b53fcd421b2168i0" rel="noreferrer"> BitBrew25 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew26} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/c7e86e0d6eb81dace44bb33ad3aae76366323faf9baefb6c787f91cd51dff6a5i0" rel="noreferrer"> BitBrew26 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew27} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/6090e3a171111ebf3a33694b1613b41dc478efb4a0ba604b83395648ec262be3i0" rel="noreferrer"> BitBrew27 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew28} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/27fedbe6149d7df5e93c039d7a587cebc1b6258e386db475659410e388e34c36i0" rel="noreferrer"> BitBrew28 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew29} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/a20bc2f69c9255ddd6b1fceaf1f8ee18bb2730922319cad142fe74ce223d78dfi0" rel="noreferrer"> BitBrew29 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew30} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/940854ff8c74847571df387ae004476ff34cff1ed1093588756b2d9504a0d180i0" rel="noreferrer"> BitBrew30 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew31} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/b29a8e5b6c1feeaf01465a42cefba95e3d3d380db729a1a88f3a6e4995d7e490i0" rel="noreferrer"> BitBrew31 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew32} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/46602da1b7d769b3660f290b46bb5cc275f1614a117d392781ae135f9132525di0" rel="noreferrer"> BitBrew32 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew33} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/0627610d083619e9aea48081555c6dd9dd1fb1845aa7eb9303bed366b44f3851i0" rel="noreferrer"> BitBrew33 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew34} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/61a905b183f4977c641a9a48e1a286b605a676f6c6367a93032f6499cbf6bb05i0" rel="noreferrer"> BitBrew34 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew35} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/8b77d4e5dd93355af9dc61bd7e30a00d8931d6df2a6d79ed3bbf458def11b110i0 " rel="noreferrer"> BitBrew35 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew36} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/808424235747ab0aa74a1570dc9f7ace226bf2555b69adbe731093935b7699e7i0" rel="noreferrer"> BitBrew36 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew37} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/f434584b2ef52e5a6b1c0744c2b3a27739b22a32c9955033a969d1804a80e922i0" rel="noreferrer"> BitBrew37 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew38} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/38e8025d75cfa0b7b18a13e75002e2363e943433aa304f98c446830973c3daa3i0" rel="noreferrer"> BitBrew38 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew39} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/090df9d14d0273a6c8a9c0c3203c6fa5022ac7aac8037baee7e0216c3e46a49ei0" rel="noreferrer"> BitBrew39 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew40} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/0a601f5e8208a769078e0f5efd995d47a32fe3d62fedb7532c8e580cecdccc15i0" rel="noreferrer"> BitBrew40 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew41} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/9253b19266a29bf37dd4797c435c438728aa2241a57cbe97c07dab17c8d32557i0" rel="noreferrer"> BitBrew41 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew42} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/3e73c7606c3704f29a0fd6ab3007e8df8c6d171f9b0a98e477438ffa6be7550bi0" rel="noreferrer"> BitBrew42 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew43} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/555d748960a66acf0c745e143078c2efc867846499ad3fe4ec3948502f8e6469i0" rel="noreferrer"> BitBrew43 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew44} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/3c329b3148693f959f09dcddfb30464252719d5d2b22e48055ba7dd72338c58ei0" rel="noreferrer"> BitBrew44 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew45} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/540e5296e797cae8a5c601430ae607f8df479b25f731d0a9754f8faddbf2b9f8i0" rel="noreferrer"> BitBrew45 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew47} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/29ebc6d53c15aaa1776a3881fea2ca31e033e79608bac6e5a79cdcc6b4e85c43i0" rel="noreferrer"> BitBrew46 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew46} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/a6b7c827c30ca69bd22b1f8d2623d4dd230f63bb5815bdc009bf3751116f7801i0" rel="noreferrer"> BitBrew47 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew48} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/9bfd0601ede75fd0e148ddef9779b1ddb3ae2ffa02fcb2bb6bc189322cc626f6i0" rel="noreferrer"> BitBrew48 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew49} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/7b2c24adcf20abaf0773b5b1b00312e6de64169bbe73f950092e41cea6db0896i0" rel="noreferrer"> BitBrew49 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>
</div>
  <div class="item">
    <img id="image" src={brew50} border={2} />
    <span class="caption"><a href="https://ordinals.com/inscription/8be12a3d4b385aa1961af80edeff35e04277eb38c7dd9884e1b1f2a573ea29f7i0" rel="noreferrer"> BitBrew50 </a> <br></br> <button id="mintButton" >
        Available 
</button></span>

  </div>
</div>
<p><em></em></p>


<h1 id="hhh1">_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>


<h1 id="hhh1"> Win </h1>
<h1 id="hhh1">_________</h1>
<h4 id="hhh1">Win a BitBrew NFT</h4>
<h1 id="hhh1">
<div>
<img id="image" src={bitbrewsgif} alt="Image" height={200} width={200} border={10}/>
</div>
</h1>



<a href="http://www.discord.gg/pixa" target="_parent">
<button id="mintButton" >
JOIN DISCORD
</button>
</a>

<h4> The BitBrews will be distributed very soon, as many have already been inscribed in the Pixa Bitcoin wallet. Join the <a href="https://discord.gg/pixa" rel="noreferrer"> Discord</a> to find out how you can acquire a BitBrew NFT! </h4>

<h1 id="hhh1">_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>





      <h1 >🍺  The  🍺</h1>
      <h1 >PixaBrews</h1>
      <h1 >_________</h1>

<h4>The PixaBrews are a special concoction of magic and wonder, beloved by the PixaWizards who are known to indulge in them perhaps a little too frequently. There are 1111 of these enchanted elixirs, and they come in a variety of flavors and have the ability to enhance magical abilities, inspire creativity, and provide the drinker with a feeling of euphoria. But be warned, the PixaBrews are not for the faint of heart and should be consumed with caution.</h4>

<h4>The PixaBrews are OS storefront ERC1155 tokens minted on the Ethereum blockchain. They are the first NFT collection created by Pixa and were released in January 2021. For more information about the other NFT collections that exist in the PixaRealm, visit our <a href="https://pixanft.com" rel="noreferrer">  website</a></h4>

<h4> Scroll down to mint a PixaBrew NFT of your own, or check out the collection on <a href="https://opensea.io/collection/pixabrews" rel="noreferrer">  OpenSea</a></h4>

<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

<h1> Mint </h1>
<h1>_________</h1>
<h4>Mint a PixaBrew NFT</h4>
<h4>ENSURE WALLET IS CONNECTED TO ETHEREUM</h4>
<h1>
<div>
<img id="image" src={charmgif} alt="Image" height={200} width={150} border={10}/>
</div>
</h1>

<button id="mintButton" >
        SOLD OUT
</button>

<h3> The PixaBrews are also available on secondary marketplaces like <a href="https://opensea.io/collection/pixabrews" rel="noreferrer"> OpenSea</a></h3>
<h4> FUTURE BREWS WILL ONLY BE AVAILABLE AS GIVEAWAYS</h4>
<h5> PixaBrews were sold on OS until mid-2022, then the team decided to make these NFTs exclusive reward for discord engagement and community event giveaways. Join the <a href="https://discord.gg/pixa" rel="noreferrer"> Discord</a> to find out how you can earn a PixaBrew NFT! </h5>



<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>





<h3>Important Links:</h3>

<h4><a href="https://linktr.ee/pixanft" rel="noreferrer">  Linktree</a> </h4>

<h4> <a href="https://opensea.io/PixaDeployer?tab=created" rel="noreferrer">  OpenSea Collections</a> </h4>

<h4><a href="https://discord.gg/pixa" rel="noreferrer">  Discord</a> </h4>

<h4><a href="https://twitter.com/pixa_nft" rel="noreferrer">  Twitter </a></h4>

<br></br>


<h3>Terms & Conditions:</h3>

<h9>(applies to all Pixa sites & smart contracts) - Use this site at your own risk. Pixa is not liable for any losses from the use of this site or associated smart contracts. Your purchase of Pixa NFTs does NOT CONSTITUTE AN INVESTMENT. Purchase of these NFTs from Pixa is meant for entertainment purposes only. Pixa makes no warranties regarding future value, express or implied, to the public or to buyers. Pixa does not offer refunds on purchased NFTs. Only purchase if you wish to own the artwork and nothing else. Only purchase with the current state of the project in mind and the assumption that no future value will be delivered. Pixa NFTs and $PIXA tokens are not currency and do not convey ownership in Pixa or its assets. You are purchasing a digital collectible, we do not guarantee any future development around these NFTs. $PIXA tokens are not shares or securities of any type. Holding a token does not entitle the holder to any ownership or other interest in Pixa (formerly known as PixaLyfe), future business operations, future revenue, or any control or ability to bind the entity. $PIXA tokens are merely a means by which you may be able to utilize certain services such as changing aspects of the artwork in your Pixa NFT. There is no guarantee that the PixaRealm platform will actually be developed, although we guarantee that it is our intention to do so.</h9>

<h1>
          <div>
      <img src={pixagif} alt="Image" height={200} width={200} border={0}/>
      </div>
</h1>
    </div>
  );
};



export default Minter;
