require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey); 

const contractABI = require('../contract-abi.json')
const contractAddress = "0x5B02557c4E9A5D886387e48fe3284668220afB53";

const contractABIpixa = require('../contract-abi-pixa.json')
const contractAddresspixa = "0xeaf211cD484118a23AD71C3F9073189C43d1311c";

export const connectWallet = async () => {
        const chainID = await window.ethereum.request({
          method: "eth_chainId",
         
        });  
    if (window.ethereum && chainID == 1) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Write a message in the text-field above.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};



export const tokenURIfinder = async(name,walletAddress) => {
  //set up your Ethereum transaction
  

  
    return {tokenwiz: name}
  }
  

export const mintPixaNFT = async(walletAddress) => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(contractABI, contractAddress);
 window.contract2 = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);

  var wazaaa = await window.contract2.methods.allowance(walletAddress, contractAddress).call();
  
  if (wazaaa < 2000) {

  const transactionParameters1 = {
        to: contractAddresspixa, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.approve(contractAddress,1000000).encodeABI()//make call to NFT smart contract 
        };

  const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters1],
        });
  } 
  else {

  }

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.mintWargPixa().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}






export const mintWarg = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(contractABI, contractAddress);

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: parseInt(web3.utils.toWei("0.04","ether")).toString(16),
        'data': window.contract.methods.mintWargEth().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}




export const mintThreeWarg = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(contractABI, contractAddress);

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: parseInt(web3.utils.toWei("0.08","ether")).toString(16),
        'data': window.contract.methods.mintThreeWargEth().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}

export const checkDopplers = async(name) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var wazaaa = await window.contract.methods.wyvernlist(name).call();
  
  if (wazaaa == true) {
    var result = "Claimed";
  } 
  else {
    var result = "Unclaimed";
  }
//make call to NFT smart contract 

  return {wizstatus: result}
}

export const claimVortical = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(contractABI, contractAddress);

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.claimWarg().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}


export const claimVorticalLoop = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(contractABI, contractAddress);

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.claimWargLoop().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}



export const checkWargsMinted = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var wazaaa = await window.contract.methods.totalSupply().call();

//make call to NFT smart contract 

  return {currentwargsminted: wazaaa}
}



export const checkPixaBalance= async(walletAddress) => {
  //set up your Ethereum transaction
    window.contract = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
    var wazaaa = await window.contract.methods.balanceOf(walletAddress).call();
  
  //make call to NFT smart contract 
  
    return {walletpixabalance: wazaaa}
  }


export const upgradeGem = async(walletAddress, wargID, power) => {
  //set up your Ethereum transaction
   window.contract = await new web3.eth.Contract(contractABI, contractAddress);
   window.contract2 = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
  
    var wazaaa = await window.contract2.methods.allowance(walletAddress, contractAddress).call();
    
    if (wazaaa < 100) {
  
    const transactionParameters1 = {
          to: contractAddresspixa, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.approve(contractAddress,1000000).encodeABI()//make call to NFT smart contract 
          };
  
    const txHash = await window.ethereum
          .request({
              method: 'eth_sendTransaction',
              params: [transactionParameters1],
          });
    } 
    else {
  
    }
  
   const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.upgradeWarg(wargID, power).encodeABI()//make call to NFT smart contract 
   };
      
  //sign the transaction via Metamask
   try {
      const txHash = await window.ethereum
          .request({
              method: 'eth_sendTransaction',
              params: [transactionParameters],
          });
      return {
          success: true,
          status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
      }
   } catch (error) {
      return {
          success: false,
          status: "😥 Something went wrong: " + error.message
      }
  
   }
  
      
  }
  